.main_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: 60px;
  width: 100%;
}
.CardMainContainer {
  margin-right: 30px;
}
/* :where(.css-dev-only-do-not-override-p7e5j5).ant-input-affix-wrapper
  > input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: var(--dark-blue) !important;
} */
.ant-input-affix-wrapper > input.ant-input::placeholder {
  color: white !important;
}
.ant-input-affix-wrapper:hover {
  cursor: pointer;
}

.ant-input-password-icon {
  color: white !important;
}

.ant-input-affix-wrapper > input.ant-input,
.ant-input-password-icon {
  color: white !important;
  background-color: var(--dark-blue) !important;
}

.ant-input-affix-wrapper:hover {
  cursor: pointer;
}

.ant-input-affix-wrapper > input.ant-input::placeholder {
  color: white !important;
}

.first-container {
  width: 65%;
  height: auto;
  background-image: url("../assets/Vector.svg");
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondMain {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.second_container {
  background-color: #172b4d;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  height: 450px;
  width: 65%;
  border-radius: 20px;
  box-shadow: 0.5px 0.5px 5px 0.5px #7a6969;
  color: white;
  margin-right: 20px;
}
.second_container:hover {
  filter: drop-shadow(3px 2px 2px rgb(204, 204, 233));
}
.first-container p {
  font-size: 15px;
}
.first-container span {
  color: white;
  font-size: 35px;
}
.first-container h1 {
  /* color: white; */
  font-size: 40px;
  margin-top: 10px;
}
.second_container p {
  font-weight: 350;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-top: 10px;
  flex-wrap: wrap;
}
.submit-button {
  background: #172b4d;
}
.formContainer {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  width: 100%;
}
.formContainerfor2 {
  margin-bottom: 50px;
}

.inputCss {
  width: 130%;
  border-radius: 20px;
  border: 1px solid yellow;
  background-color: #172b4d;
  color: white;
  margin-bottom: 2px;
}
.inputCssPassword {
  width: 280px;
  border-radius: 20px;
  border: 1px solid yellow;
  background-color: #172b4d;
  color: white;
  /* margin-bottom: 20px;  */
}
.LogoMain {
  height: 70px;
  margin-bottom: 10px;
}
.logoLearn {
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}
.Forgotcss p {
  transition: transform 0.9s ease;
}
.Forgotcss p:hover {
  transform: scale(1.2);
  transition: transform 0.9s ease;
  text-decoration: underline;
}
.srcma {
  filter: drop-shadow(10px 7px 6px rgb(159, 160, 159));
}
.Forgotcss {
  display: flex;
  justify-content: space-around;
  color: white;
  width: 100%;
  cursor: pointer;
}
.inputCss::placeholder {
  color: white;
}
.inputCssPassword::placeholder {
  color: white;
}
.headingtext {
  font-size: 20px;
  font-weight: 350;
  color: white;
  margin: 10px 10px 30px 10px;
  text-align: center;
}
@media (max-width: 600px) {
  .main_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .first-container {
    font-size: 13px;
    height: 150px;
    width: 300px;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .first-container {
    margin-top: 4px;
  }
  .second_container {
    font-size: 13px;
    height: 400px;
    width: 300px;
    border-radius: 0px 0px 20px 20px;
  }
  .inputCss {
    width: 100%;
  }
}
